import React, { useState } from "react";
import { graphql } from 'gatsby'
import {default as GatsbyImage} from 'gatsby-image';

import { Dialog } from "@reach/dialog";
import VisuallyHidden from "@reach/visually-hidden";
import "@reach/dialog/styles.css"

import Layout from "../components/layout";
import SEO from "../components/seo";

import AccordionElement from "../components/accordion/accordion_element";
import Accordion from "../components/accordion/accordion";

import MainObjects from "../components/mainobjects/mainobjects";


const IndexPageEn = props => {

    // define language_id
    let language_id = 1;

    // pull out welcomesection from data in current language
    let welcome = props.data.allWelcomesection.nodes;
    welcome = welcome.find(el => el.language_id == language_id);

    
    // pull out questions from data
    let questions = props.data.allQuestions.nodes;
    questions = questions.filter(el => el.sys_languages_id == language_id);


    // define if accordion should be open by default
    let isAccordionDefaultOpen = true;
    if (typeof window !== "undefined") {
        if (window.innerWidth < 720) {
            isAccordionDefaultOpen = false;
        }
    }

    let [showDialog, setShowDialog] = useState(false);


    const translations = {
        museum_objects : {
            1: "Museum items as witnesses of the past",
            2: "Museumsobjekte als Zeitzeugen der Geschichte"
        },
        objects_await_you : {
            1: "These 30 museum items date back to different periods. They reflect a broad spectrum of Tulln's town history. The VIRTULLEUM app provides you with detailed interactive information on the items.",
            2: "Sie sehen hier 30 ausgewählte Objekte. Diese stammen aus unterschiedlichen Epochen und spiegeln ein sehr breites Spektrum der Tullner Stadtgeschichte wider. Ausführliche interaktive Informationen zu den Objekten erhalten Sie in der VIRTULLEUM-App."
        }
    }

    return (
        <Layout pathContext={props.pathContext}>
            <SEO title="Welcome" />
 
            <section className="welcome_section main_content_wrapper">
                <article className="welcome_article">
                    <div id="top" style={{display: "block", position: "relative", top: "-100px", visibility: "hidden"}}></div>
                    <h1>{welcome.title}</h1>

                    <div
                        className="welcome_description"
                        dangerouslySetInnerHTML={{
                            __html: welcome.description
                        }}
                    ></div>

                    <div className="app_download_wrapper">
                        <a href={welcome.virtulleum_link_ios} className="ios_btn" target="_blank" rel="noopener noreferrer">
                            <img
                                src="/images/app_store.svg"
                                alt="Get Virtulleum in App Store Icon"
                            />
                        </a>
                        <a href={welcome.virtulleum_link_android} className="android_btn" target="_blank" rel="noopener noreferrer">
                            <img
                                src="/images/play_store.svg"
                                alt="Get Virtulleum in Play Store Icon"
                            />
                        </a>
                    </div>

                    <p>{welcome.description_more}</p>
                </article>

                <section className="tulln_welcome_video" onClick={() => setShowDialog(true)}>

                    <GatsbyImage fluid={welcome.welcomeImage.childImageSharp.fluid} alt="Virtulleum Willkommen Bild" />

                    <button className="round_btn dark_btn play_welcome_video_btn js_play_welcome_video_btn">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="play_icon"
                            viewBox="0 0 13.333 20"
                        >
                            <path d="M1.111,20H.667A1.222,1.222,0,0,1,0,18.889V1.111A1.222,1.222,0,0,1,.667,0,1.665,1.665,0,0,1,1.778.222L12.889,9.111a1.111,1.111,0,0,1,0,1.778L1.778,19.778A.816.816,0,0,1,1.111,20Z" />
                        </svg>
                    </button>
                </section>
            </section>

            <section className="about_virtulleum_section accordion_wrapper main_content_wrapper">

                <Accordion allowOnlyOneOpen={true} >   
                    {questions.map((question, index) => (
                        <AccordionElement
                            key={index}
                            title={question.title}
                            defaultOpen={index === 0 ? isAccordionDefaultOpen : false}
                        >
                            <div
                                className="accordion_text_wrapper"
                                dangerouslySetInnerHTML={{
                                    __html: question.description
                                }}
                            ></div>
                        </AccordionElement>
                    ))}
                </Accordion>

                <div className="dust_deco_1"></div>
                <div className="dust_deco_2"></div>
            </section>
            {/* /about_virtulleum_section */}

            <div className="main_objects_deco_wrapper" >
                <div id="museumsobjekte" style={{display: "block", position: "relative", top: "-4rem", visibility: "hidden"}}></div>
                <div className="main_objects_wrapper main_content_wrapper">
                    <h2>{translations.museum_objects[language_id]}</h2>
                    <p>{translations.objects_await_you[language_id]}</p>

                    <section className="main_objects_grid">
                        <MainObjects lang_id={language_id} />
                    </section>
                </div>

                <div className="main_content_leaf_deco_1"></div>
                <div className="main_content_leaf_deco_2"></div>

                <div className="main_content_leaf_deco_3"></div>
                <div className="main_content_leaf_deco_4"></div>
            </div>

            <Dialog
                isOpen={showDialog}
                onDismiss={() => setShowDialog(false)}
            >
                <button
                    className="close-button"
                    onClick={() => setShowDialog(false)}
                >
                    <VisuallyHidden>Close</VisuallyHidden>
                    <span aria-hidden>×</span>
                </button>


                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/nPvFvfXV8h0?autoplay=1&modestbranding=1&showinfo=0&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </Dialog>

        </Layout>
    );
};

export default IndexPageEn;

export const query = graphql`
    query HomePageQueryEn {
        allWelcomesection  {
            nodes {
                title
                description
                description_more
                virtulleum_link_ios
                virtulleum_link_android
                imprint_video
                language_id
                welcomeImage {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        allQuestions {
            nodes {
                title
                description
                sys_languages_id
            }
        }
    }
`